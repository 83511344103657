.PageNotFound-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.PageNotFound-404-header {
    color: white;
    font-weight: 700;
    font-size: 8rem;
}

.PageNotFound-message {
    color: black;
    font-weight: 400;
}
