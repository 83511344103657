.Home-mainStack {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70vh;
    flex-direction: row !important;
}

.cardContainer {
    flex: 1 !important;
    min-width: 0; /* required to make columns equal if overflowing text inside them makes it grow bigger */
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.18);
    padding: 1rem;
}

.Home-profileCard > .vstack {
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1rem;
}

.Home-monkeysCard {
    /*
        The `padding-right` primarily, must be set on the `scrollableList`
        otherwise the button's right shadow will be cut off. Setting
        overflow-x scroll and y to visible is not possible. Also, reset
        the padding-bottom, which is not required to have by the list
    */
    padding: 1rem 0 0 0;
}

.ProfileCard-layoutStack {
    flex-direction: column;
    gap: 3vh;
}

.ProfileCard-accountName {
    color: black;
    font-weight: 700;
    margin-bottom: 0px;
}

.ProfileCard-accountAmounts {
    color: black;
    font-weight: 400;
}

.ProfileCard-greenStatus {
    color: var(--green);
    text-align: center;
    border: 2px solid var(--green);
    border-radius: 6px;
    padding: 0px 6px 0px 6px;
}

.ProfileCard-lastFightInfoContainer {
    border: 2px solid lightgray;
    border-radius: 6px;
    padding: 3px 6px 3px 6px;
}

.ProfileCard-lastFightInfoContainer .detailRow {
    justify-content: center;
    gap: 4px;
}

.ProfileCard-lastFightInfoContainer .detailTitle {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ProfileCard-footerSubtitle {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    text-align: center;
}

.ProfileCard-actionButton {
    min-width: 0; /* Won't be keeping the identical width when resized otherwise */
    flex: 1 1 33%; /* Make all buttons occupy the same width */
}

.ProfileCard-actionButton > .dropdown-toggle {
    width: 100%; /* For whatever reason the button does not occupy full-width */
}

/* List */
.MonkeysCard-scrollableList {
    max-height: 100%;
    margin-top: 1rem;
    overflow-y: scroll;
    padding: 0 1rem 0 1rem; /* Must be set on the list otherwise button's shadow gets cut off */
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .Home-mainStack {
        position: static;
        margin: 3% 0 3% 0;
        top: auto;
        left: auto;
        transform: none;
        flex-direction: column !important;
        height: auto;
    }

    .Home-profileCard {
        flex: 0.75 !important;
    }

    .Home-monkeysCard {
        height: 75vh;
    }

    .ProfileCard-lastFightInfoContainer {
        max-width: 100%;
    }
}

/*
    The monkey's silhouette tends to be too small when using the
    landscape orientation, thus display the content horizontally 
*/
@media screen and (max-height: 500px) {
    @media screen and (min-width: 390px) {
        .ProfileCard-layoutStack {
            flex-direction: row !important;
        }

        .ProfileCard-layoutStack > div {
            flex: 0 0 auto !important;
            width: 50%;
            justify-content: center;
        }
    }
}
/*
    Otherwise on smaller resolutions like iPhone SE the action
    buttons will be displayed out of their container (the card)
*/
@media only screen and (max-width: 380px) {
    .ProfileCard-actionButton.btn {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .ProfileCard-actionButton > .dropdown-toggle {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
