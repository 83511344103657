.Plantation-ownerName {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.PlantationFieldContainer {
    background-color: rgb(160, 125, 70);
    border-radius: 6px;
    box-shadow: 2px 2px 4px #0000007d;
    padding: 10px !important;
}

.PlantationFieldRow {
    margin: 0 !important;
}

.PlantationFieldRow>* {
    padding: 0 !important;
}

.PlantationFieldCol {
    flex: 0 0 auto !important;
    aspect-ratio: 1 / 1; /* Width is calc. based on % but make sure height is identical */
    position: relative; /* Images are shown on top of one another */
    border: 2px solid transparent; /* Active border will not resize the content then */
}

.PlantationFieldCol.flashing {
    animation: pulse 2s ease-in infinite;
    &:hover { animation: none; }
}

.PlantationFieldCol img {
    position: absolute; /* Make images shown on top of one another */
    user-select: none;
}

.PlantationFieldCol:hover {
    border: 2px dotted rgb(0, 0, 0, 0.6);
    border-radius: 6px;
}

.PlantationFieldCol:hover > .TreeStateInfo {
    display: block;
}

.EmptyBananaTreeIcon {
    width: 20%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto; /* 100% makes it a little blurry on Chrome */
}

.BananaTreeIcon {
    width: 100%;
    height: auto; /* 100% makes it a little blurry on Chrome */
    padding: 3px; /* Just so there is more space between the pic and the border*/
}

.TreeStateInfo {
    display: none; /* Shown upon hovering over the PlantationFieldCol */
    position: absolute;
    font-size: 0.6rem;
    color: white;
    background-color: rgb(0, 0, 0, 0.6);
    text-align: right;
    right: 3px;
    bottom: 3px;
    border-radius: 3px;
    padding: 0px 2px 0px 2px;
}

@keyframes pulse {
    50% { opacity: 0.3; }
}

@media only screen and (min-width: 992px) {
    .PlantationFieldCol {
        width: 10% !important;
    }
}

@media only screen and (max-width: 992px) {
    .PlantationFieldCol {
        width: 14.28% !important;
    }
}

@media only screen and (max-width: 768px) {
    .PlantationFieldCol {
        width: 16.66% !important;
    }
}

@media only screen and (max-width: 576px) {
    .PlantationFieldCol {
        width: 20% !important;
    }
}
