/* Make padding between items a little smaller than it is by default */
.WeaponsGrid .row>* {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.WeaponsGridItem {
    position: relative; /* Required to position the quantity label */
    height: 100%;
    aspect-ratio: 1 / 1; /* Make height same as width */
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.WeaponsGridItem.selected {
    border: 2px dotted var(--yellow-primary);
}

.WeaponsGridItem-icon {
    width: 80%;
    height: 80%;
    opacity: 0.4;
}

.WeaponsGridItem-icon:hover {
    opacity: 1;
}

.WeaponsGridItem-icon.selected {
    opacity: 1;
}

.WeaponsGridItem-footerMessage {
    font-size: 0.75rem;
    font-weight: 300;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: -0.25rem;
}
