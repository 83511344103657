.fight-ready-video {
    width: 40%;
    border-radius: 6px;
    display: block;
    margin: 2rem auto 0 auto;
    background-color: lightgray;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .fight-ready-video {
        width: 100%;
    }
}
