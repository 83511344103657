.monkeySilhouetteImage {
    width: auto;
    height: 30vh;
    pointer-events: none;
}

.weaponContainer {
    position: absolute;
    width: auto;
    height: 10vh; /* 1/3 from the total height of the silhouette */
    cursor: pointer;
}

.weaponContainer .image {
    height: 100%;
}

.weaponContainer .image:hover {
    box-shadow: 0 0 0 1px black; /* border affects the image's size */
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.7);
}

.weaponContainer .weaponLifespanBar {
    position: absolute;
    width: 20px;
    height: 6px;
    border: 1px solid black;
    border-radius: 6px;
}

.setWeaponButton {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 32px;
    height: 32px;
    border: 2px dotted black;
    border-radius: 6px;
    padding: 0;
    background-color: rgb(242, 189, 29, 0.7); /* --yellow-primary with opacity */
}
  
.setWeaponButton::before {
    display: flex;
    align-items: center;
    content: "+";
    color: black;
    font-size: 26px;    
    font-family: monospace;
}

.setWeaponButton:hover {
    background-color: rgb(255, 255, 255, 0.7)
}
