.toast {
    background-color: white !important;
    border: lightgray solid 3px !important;
}

.toast.generic-alert {
    border-left-color: var(--green) !important;
}

.toast.error {
    border-left-color: var(--red) !important;
}

.toast.success {
    border-left-color: var(--green) !important;
}

.toast-header {
    border-bottom: lightgray solid thin !important;
}

.toast-header-title {
    margin: 0;
}

.toast-header-title.generic-alert {
    color: var(--green) !important;
}

.toast-header-title.error {
    color: var(--red) !important;
}

.toast-header-title.success {
    color: var(--green) !important;
}

.toast-body {
    font-size: 1rem;
}

.toast-body a {
    text-decoration: underline !important;
}
