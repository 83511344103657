.accountNameInput {
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.nameExtensionInput {
    border-left: 0px !important;
    text-align: center;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}