.InventoryModal {
    width: 360px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

@media screen and (max-width: 380px) {
    .InventoryModal {
        width: 95% !important;
    }
}

.AssetOptionsModal-weaponStatsBar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 1.5rem;
    font-weight: 400;
    font-size: 0.65rem;
    text-transform: uppercase;
    border: 1px solid black;
    border-radius: 4px;
}

/* Make padding between items a little smaller than it is by default */
.InventoryAssetsGrid .row>* {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.InventoryAssetGridItem {
    position: relative; /* Required to position the quantity label */
    height: 100%;
    aspect-ratio: 1 / 1; /* Make height same as width */
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.InventoryAssetGridItem:hover {
    border: 1px solid var(--yellow-primary);
}

.InventoryAssetGridItem-icon {
    width: 80%;
    height: 80%;
}

.InventoryAssetGridItem-icon.disabled {
    opacity: 0.4;
}

.InventoryAssetGridItem-icon.disabled:hover {
    opacity: 1;
}

.InventoryAssetGridItem-quantity {
    position: absolute;
    font-size: 0.75em;
    float: left;
    right: 4px;
    bottom: 0px;
}

.CraftableInventoryAssetGridItem-timeRemaining {
    position: absolute;
    width: 100%;
    font-size: 0.6rem;
    text-align: center;
    bottom: 0;
    backdrop-filter: blur(10px);
}

.InventoryAssetsList-noAssetsMessage {
    width: 66%;
    text-align: center;
    font-weight: 300;
    font-size: 0.75rem;
    align-self: center;
}

.InventoryAssetsList-assetsCount {
    font-weight: 300;
    font-size: 0.7rem;
    text-align: right;
    margin-top: 6px;
    margin-right: 0.2rem;
}

.InventoryAssetsList-sectionHeader {
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0rem 0.4rem 0.4rem 0.4rem;
}

.InventoryAssetsList-sectionSubtitle {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0rem 0.4rem 0.4rem 0.4rem;
}

.InventoryAssetsList-footerMessage {
    font-size: 0.75rem;
    font-weight: 300;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: -0.25rem;
}
