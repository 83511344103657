/* Header */
.Header-stack {
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0 0.75rem 0;
}

.Header-logo {
    width: auto;
    height: 3rem;
}

.Header-blockchainIcon {
    display: block;
    height: 2.75rem;
    padding: 4px 8px 4px 8px;
    margin-left: auto;
    border: 2px solid black;
    border-radius: 3rem;
}

.dropdown-item img {
    width: auto;
    height: 1.5rem; /* font size is 1rem */
    padding-right: 0.35rem; /* dropdown left padding is 1rem */
}
