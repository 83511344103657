.range-slider {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 2px;
    width: 100%;
    background-color: lightgray;
    margin: 20px auto;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

.bubble-wrapper {
	position: absolute;
	top: -24px;
}

.bubble-container {
    display: block;
	position: absolute;
	padding: 0px 6px 0px 6px;
	height: 30px;
    transform: translate(-50%, 0);
	border-radius: 6px;
    border: 2px solid;
}

.bubble-container:before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-top: 10px solid;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	top: 100%;
	left: 50%;
	margin-left: -5px;
}

/* Color variations */
.range-slider.banana::-webkit-slider-thumb {
    background-color: var(--yellow-primary);
}

.bubble-container.banana {
    border-color: var(--yellow-primary);
}

.bubble-container.banana:before {
    border-top-color: var(--yellow-primary);
}

.range-slider.peel::-webkit-slider-thumb {
    background-color: var(--brown-peel);
}

.bubble-container.peel {
    border-color: var(--brown-peel);
}

.bubble-container.peel:before {
    border-top-color: var(--brown-peel);
}