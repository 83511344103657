.chainButton {
    min-width: 0;
    flex: 1 1 50%;
}

.chainButton img {
    width: auto;
    height: 1.5rem; /* font size is 1rem */
    padding-right: 0.35rem; /* dropdown left padding is 1rem */
}
