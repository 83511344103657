* {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

:root {
    --yellow-primary: rgb(242, 189, 29);
    --yellow-secondary: rgb(223, 184, 31);
    --brown-peel: rgb(127, 93, 68);
    --orange: rgb(191, 87, 0);
    --red: rgb(191, 23, 53);
    --green: rgb(191, 182, 4);
}

body {
    background-color: white !important;
    touch-action: pan-x pan-y; /* Disable zooming */
}

a {
    color: var(--yellow-secondary) !important;
    text-decoration: none !important;
}

input:focus, input.form-control:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

/* Global */
.buttonTextLink {
    color: var(--yellow-secondary);
    padding: 0;
    border: none;
    background: none;
}

/* Bootstrap - Buttons */
.btn, .btn:hover, .btn:active, .btn:visited {
    color: black !important;
    height: 2.75rem;
    text-transform: uppercase;
    border-width: 2px !important;
    border-radius: 3rem !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    font-weight: 400 !important;
}

.btn-primary, .btn-primary:active, .btn-primary:visited {
    background-color: var(--yellow-primary) !important;
    border-color: black !important;
}

.btn-primary:hover {
    color: black !important;
    background-color: white !important;
}

.btn-secondary, .btn-secondary:active, .btn-secondary:visited {
    background-color: white !important;
    border-color: white !important;
}

.btn-secondary:hover {
    border-color: black !important;
}

/* Bootstrap - Modal */
.modal-body p {
    font-weight: 300;
}

.modal-backdrop-blurred {
    opacity: 1 !important;
    background: rgb(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(4px);
    /*
        There might be other modals already open underneath.
        For example when logging in later on in the game
    */
    z-index: calc(var(--bs-backdrop-zindex) + 5) !important;
}

/* Dropdown */
.dropdown-item {
    color: black !important;
}

.dropdown-item.disabled {
    color: lightgray !important;
}

.dropdown-item:hover, .dropdown-item:focus {
    background-color: var(--yellow-primary) !important;
}

/* Form.Text */
.text-muted.red {
    color: var(--red) !important;
}

/* Custom */
.OptionsPopupModal {
    width: 250px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

/*
    Adjust the z-indexes as we're displaying some modals
    above another modal whose window gets positioned above our
    backdrop (tinted background) since the z-index values are fixed
*/
.ModalWithTintedBackdrop {
    --bs-backdrop-opacity: 0.65 !important; /* Make it a little darker. Applied to .modal-backdrop.show */
    z-index: calc(var(--bs-backdrop-zindex) + 5) !important;
}
