.bananaImage {
    width: 80%;
    height: auto;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.bananaImageMoving {
    position: absolute;
    opacity: 0; /* Starting / ending */
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.energyProgressBar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
    font-weight: 400;
    font-size: 0.6rem;
    text-transform: uppercase;
    border: 1px solid lightgray;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
}

.rankingIcon {
    width: auto;
    height: 100%;
    cursor: pointer;
    padding: 4px;
}

.deductedAmountLabel {
    position: absolute;
    opacity: 0; /* Starting / ending */
}

.earnBananasButtonTitle {
    font-size: 0.8rem;
    margin-top: 1.75rem;
}

.energyProgressBar:active {
    border: 1px solid black;
}

.energyOptionItem {
    cursor: pointer;
    padding-bottom: 6px;
    border-radius: 4px;
    padding: 4px;
}

.energyOptionItem.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.energyOptionItem:active {
    background-color: var(--yellow-primary);
}

.energyOptionItem .title {
    font-weight: 400;
}

.energyOptionItem .description {
    font-weight: 300;
    font-size: 0.8rem;
}

.rankingView.tableRow {
    gap: 4px;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0px 6px 0px 6px;
}

.rankingView.tableRow:active {
    background-color: var(--yellow-primary);
}

.rankingView.name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tgLinkTextArea {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.06);
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 6px;
    resize: none;
}

/* Animations */
@keyframes press_up {
    from { opacity: 0.5;} /* Opacity on mouse-down */
    to { opacity: 1; }
}

@keyframes energy_bar_warning {
    from { background: var(--red); }
    to {}
}

@keyframes appear_and_move_coin {
    0% {
        opacity: 1;
    }

    66% {
        opacity: 0.66;
        width: 60px;
    }

    100% {
        width: 80px;
        top: -100%;
        opacity: 0;
    }
}

@keyframes fade_out_amount_deducted {
    from { opacity: 1; }
    to { opacity: 0; top: var(--amountDeductedDestTop); }
}

/* Misc */
@media (hover: hover) {
    /*
       Only if hover supported which is not the case on touch devices
       in which case the style applied would stay on even after touch-up
   */
   .energyProgressBar:hover {
        border: 1px solid black;
    }

    .energyOptionItem:hover {
        background-color: var(--yellow-primary);
    }

    .rankingView.tableRow:hover {
        background-color: var(--yellow-primary);
    }
}
