.tokenIcon {
    padding-left: 34px !important; /* (icon width + icon padding + text padding) */
    background-size: 22px !important;
}

.arrowContainer {
    height: 22px;
    margin-bottom: 1rem; /* Same as Form.Group used */
}

.arrow {
    width: 18px;
    height: 3px;
    position: relative;
    top: 8px;
    background: black;
    margin: 0 auto;
    transform: rotate(90deg);
}

.arrow:before, .arrow:after {
    content: "";
    background: black;
    position: absolute;
    height: 3px;
    width: 14px;
}

.arrow:before {
    right: -3px;
    bottom: -4px;
    transform: rotate(-45deg);
}

.arrow:after {
    right: -3px;
    top: -4px;
    transform: rotate(45deg);
}

.poweredByExchangeStack {
    justify-content: center;
    gap: 6px;
    margin-top: 2rem;
    margin-bottom: -0.25rem;
}

.exchangeLogo {
    height: 1.1rem;
}
