.CircularProgressbar-path {
    stroke: black !important;
}

.CircularProgressbar-trail {
    stroke: lightgray !important;
}

.CircularProgressbar-text {
    fill: black !important;
}

.SelectTokenTabs .nav-link {
    color: gray;
}

.SelectTokenTabs .nav-link:focus, .nav-link:hover {
    color: black;
}

.TabContent {
    text-align: center;
    font-weight: 300;
}