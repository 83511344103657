.App {
    justify-Content: space-between;
    min-height: 100vh;
    background-color: var(--yellow-primary);
}

.App-content {
    flex: 1;
    position: relative;
}
