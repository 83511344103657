.Footer {
    text-align: center;
    color: black;
    background-color: white;
    font-size: 0.9rem;
    padding: 1rem;
}

.Footer-link:hover {
    text-shadow: 1px 1px 8px var(--yellow-primary);
}
