.MonkeysCard-scrollableListItem {
    height: 50px;
    border-bottom: lightgray solid 1px;
    cursor: pointer;
}

.MonkeysCard-scrollableListItem:not(:active) {
    transition: background-color 200ms ease-in;
}

.MonkeysCard-scrollableListItem:active {
    background-color: lightgray;
}

.MonkeysListItem-superscriptStatus {
    color: black;
    font-size: 0.55rem;
    top: -0.5rem;
    left: 0.1rem;
}

.MonkeysListItem-superscriptStatus.alpha {
    border: 1px solid black;
    background-color: var(--yellow-primary);
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
}

.MonkeysListItem-status {
    font-size: 0.7rem;
    font-weight: 400;
    flex-shrink: 0; /* Make sure the status is visible at all times */
}

.MonkeysListItem-status.orange {
    color: var(--orange);
}

.MonkeysListItem-status.red {
    color: rgb(201, 16, 15);
}

.MonkeysListItem-status.green {
    color: var(--green);
}

.MonkeysListItem-userStatus {
    color: darkgray;
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.MonkeysListItem-actionButton {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    padding: 0;
    border: 0;
    margin-left: 10px;
    flex-shrink: 0; /* make width to not shrink at all */
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 400ms;
    transform: scale(1, 1);
    overflow: hidden;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
}

.MonkeysListItem-actionButton.green {
    background-color: var(--green);
}

.MonkeysListItem-actionButton.green:active {
    background-color: rgb(133, 127, 2);
}

.MonkeysListItem-actionButton.red {
    background-color: var(--red);
}

.MonkeysListItem-actionButton.red:active {
    background-color: rgb(133, 15, 37);
}

.MonkeysListItem-actionButton .actionButtonIcon {
    width: 100%;
}

/* Misc */
@media (hover: hover) {
     /*
        Only if hover supported which is not the case on touch devices
        in which case the style applied would stay on even after touch-up
    */
    .MonkeysListItem-actionButton:hover {
        transform: scale(1.05, 1.05);
    }
}
