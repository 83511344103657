.TreeContainer {
    width: 128px;
    height: 128px;
    position: relative;
}

.TreeContainer img {
    position: absolute;
}

.BananaCoinIcon {
    height: 0.33rem; /* Starting size */
    position: absolute;
    top: 107px;
    opacity: 0;
}
  
/* Animations */
@keyframes disappear {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes appear_and_move_coin {
    0% {
        top: 107px;
        opacity: 0.66;
    }

    33% {
        height: 1.3rem;
        opacity: 1;
    }

    100% {
        top: 175px;
        height: 0.33rem;
        opacity: 0.66;
    }
}